@import "index.scss";
//general
.main {
  min-height: 100vh;
}
.container {
  margin: 0 auto;
}
//hero
.App-hero {
  background: rgba(9, 31, 44, 0.3);
}
.App-hero_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: $primary-light;
}
//navbar
.header {
  background: linear-gradient(
    180deg,
    rgba(9, 31, 44, 0.6) 0%,
    rgba(9, 31, 44, 0) 94.79%
  );
}
nav {
  background-color: transparent !important;
}
//footer
footer {
  background-color: $primary-dark !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  color: $primary-light !important;
}

footer li {
  margin-top: 0 !important;
  color: $primary-light !important;
  font-size: 0.9rem !important;
}

footer h2 {
  margin-bottom: 1rem !important;
  color: $primary-light !important;
  font-weight: 700 !important;
}

a[data-testid="flowbite-footer-brand"] img {
  width: 180px;
  height: auto;
}

.footer-content {
  @include font("Poppins", 12px, 400, 18px, "normal");
}

.copy-right {
  @include font("Poppins", 12px, 400, 18px, "normal");
}
footer h2[data-testid="flowbite-footer-title"] {
  color: #cfd1d5 !important;
}

footer .divider {
  border-top: 1px solid #cfd1d557;
  margin-top: 30px;
  margin-bottom: 27px;
  width: 100%;
}
//section
.section_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: $primary-dark;
}
//card
.cardItem div[data-testid="flowbite-card"] {
  border: none;
  border-radius: 8px !important;
  box-shadow: none !important;
  min-height: 280px;
  height: 300px;
  background-color: $dark-six;
}

.cardItem .cardWrapper {
  display: block;
  height: 100%;
}
.cardItem .cardWrapper :where(img, h4) {
  margin-bottom: 1rem;
}
//Resonsive
@media only screen and (max-width: 639px) {
  h1 {
    @include font($font_layout1, 38px, 700, 45.6px, "normal");
  }
  h2 {
    @include font($font_layout1, 34px, 700, 40.8px, "normal");
    max-width: 335px;
  }
  .emphasis_para {
    @include font($font_layout1, 16px, 400, 22.4px, "normal");
  }
}
